export const css = (el, object) => {
  Object.keys(object).forEach((k) => {
    el.style[k] = object[k]
  })
}
export const show = (el) => {
  css(el, { display: 'block' })
}

export const hide = (el) => {
  css(el, { display: 'none' })
}

export const hasClass = (el, className) => {
  return (
    String(el.getAttribute('class'))
      .split(/\s+/)
      .map((v) => v.trim())
      .indexOf(className.trim()) >= 0
  )
}

export const addClass = (el, className) => {
  if (!el) return
  if (hasClass(el, className)) return

  el.setAttribute('class', el.getAttribute('class').trim() + ' ' + className)
}

export const removeClass = (el, className) => {
  if (!el) return
  el.setAttribute(
    'class',
    el.getAttribute('class').replace(className.trim(), '')
  )
}

export const toggleClass = (el, className, state = null) => {
  ;(state === null && hasClass(el, className)) || state === false
    ? removeClass(el, className)
    : addClass(el, className)
}

export const next = (el, cb) => {
  let current = el.nextSibling
  while (current) {
    if (cb(current)) {
      return current
    }
    current = current.nextSibling
  }
  return null
}

export const closest = (el, cb) => {
  let current = el.parentNode
  while (current) {
    if (cb(current)) {
      return current
    }
    current = current.parentNode
  }
  return null
}

export const isChecked = (el) => {
  return !!el.checked
}

export const documentReady = (f) => {
  document.addEventListener('DOMContentLoaded', function () {
    if (typeof f === 'function') {
      f()
    }
  })
}

export const find = (sel, elem = null) => (elem || document).querySelector(sel)
export const findAll = (sel, elem = null) =>
  (elem || document).querySelectorAll(sel)

// Like addEventListener, but handles elements added after the listener is added. Calls the callback passing the event
// as an argument and the element that matched the selector as `this`.
export const attachDynamicEvent = (selector, event, cb, options) => {
  document.addEventListener(
    event,
    (e) => {
      let matchingElement = e.target.closest(selector)
      if (matchingElement) {
        cb.call(matchingElement, e)
      }
    },
    options
  )
}

/* Returns a promise that resolves when the selector is present on the page. Fires the callback at most one time. */
export const whenSelectorPresent = (selector) => {
  return new Promise((resolve, _reject) => {
    if (document.querySelector(selector)) {
      resolve()
      return
    }

    const config = { attributes: false, childList: true, subtree: true }
    const observerCallback = (_mutationList, observer) => {
      if (document.querySelector(selector)) {
        resolve()
        observer.disconnect()
      }
    }
    const observer = new MutationObserver(observerCallback)
    observer.observe(document.documentElement, config)

    document.addEventListener('DOMContentLoaded', () => {
      if (document.querySelector(selector)) {
        resolve()
        observer.disconnect()
      }
    })
  })
}

/*
 * Runs the passed callback for each matching selector present on the page. Fires multiple times if the selector is
 * present multiple times, but only once per element.
 */
export const whenSelectorPresentAll = (selector, callback) => {
  calls[callback] = []
  document.querySelectorAll(selector).forEach((el) => {
    runCallbackOnce(callback, el)
  })
  document.addEventListener('DOMContentLoaded', () =>
    document.querySelectorAll(selector).forEach((el) => {
      runCallbackOnce(callback, el)
    })
  )
  const config = { attributes: false, childList: true, subtree: true }
  const observerCallback = (_mutationList, _observer) => {
    document.querySelectorAll(selector).forEach((el) => {
      runCallbackOnce(callback, el)
    })
  }
  const observer = new MutationObserver(observerCallback)
  observer.observe(document.documentElement, config)
}

let calls = {}
function runCallbackOnce(callback, arg) {
  calls[callback] ||= []
  if (calls[callback].includes(arg)) {
    return
  }
  calls[callback].push(arg)
  callback(arg)
}

/* Returns a promise that resolves when the selector is visible on the page (with the specified margin). Fires the callback at most one time. */
export function whenSelectorVisible(selector, margin) {
  return new Promise((resolve, _reject) => {
    whenSelectorPresentAll(selector, (element) => {
      if ('IntersectionObserver' in window) {
        let observer = new IntersectionObserver(
          (entries) => {
            if (entries.some((entry) => entry.isIntersecting)) {
              resolve()
              observer.unobserve(element)
            }
          },
          { rootMargin: margin || '0' }
        )

        observer.observe(element)
      } else {
        // Fall back, load now
        resolve()
      }
    })
  })
}

export function ancestorHasDisplayNone(element) {
  let currentElement = element

  while (currentElement) {
    if (window.getComputedStyle(currentElement).display === 'none') {
      return true
    }
    currentElement = currentElement.parentElement
  }

  return false
}
